import * as React from 'react'
import styled from 'styled-components'
import theme from '../../atoms/theme'

interface Props {
  step: string
  details: string
}

const StepContainer = styled.div`
  background: ${theme.colours.secondary};
  color: ${theme.colours.white};
  padding: 3.2rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    padding: 1.6rem;
  }
`

const Title = styled.h3`
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    font-size: 2rem;
  }
`

const StepBlock = (props: Props): JSX.Element => {
  return (
    <StepContainer>
      <Title>{props.step}</Title>
      {props.details}
    </StepContainer>
  )
}

export default StepBlock

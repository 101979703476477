import React, { FormEvent, useState, ChangeEvent, useEffect } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import theme from '../../atoms/theme'
import AddressInput from './address-input/address-input'
import Button from '../../molecules/button'
import ResidencySection from './nz-residency-section'
import OwnerDetailsSection from './owner-details-section'
import BusinessDetailsSection from './business-details-section'
import FormSection from './form-section'
import isUrl from 'validator/lib/isURL'
import isEmail from 'validator/lib/isEmail'
import ReCAPTCHA from 'react-google-recaptcha'
import { CLIENT_SALESFORCE_URL } from '../../constants'
import TextArea from '../../atoms/text-area'
import Separator from '../../atoms/separator'

interface Place {
  street: string
  province: string
  zip: string
  city: string
  country: string
  formattedAddress: string
}

const FormContainer = styled.div`
  padding: 4.8rem 3.2rem 0;
  padding-bottom: 2.8rem;
  margin-bottom: 2.4rem;
  border-right: 0.1rem solid ${theme.colours.gray3};
  border-left: 0.1rem solid ${theme.colours.gray3};
  border-bottom: 0.1rem solid ${theme.colours.gray3};
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    border: none;
  }
`

const ErrorMessage = styled.div`
  background: ${theme.colours.danger1Hover};
  border: 0.1rem solid ${theme.colours.danger1};
  padding: 2.4rem;
  width: 82.2rem;
  a {
    font-weight: bold;
  }
`

const RequiredFieldLabel = styled.label`
  margin-bottom: 2.4rem;
  display: block;
`

const ReCAPTCHAContainer = styled.div`
  margin-bottom: 2rem;
`

const convertYesNo = (value: string): boolean | null => {
  switch (value) {
    case 'yes':
      return true
    case 'no':
      return false
    default:
      return null
  }
}

const defaultAddressState = {
  street: '',
  city: '',
  province: '',
  zip: '',
  country: 'New Zealand',
  formattedAddress: '',
}

const RegistrationForm = (): JSX.Element => {
  const [isResidentOrCitizen, setIsResidentOrCitizen] = useState(null)
  const [title, setTitle] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [about, setAbout] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [isSocialEnterprise, setIsSocialEnterprise] = useState('')
  const [website, setWebsite] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [isFormValid, setIsFormValid] = useState(false)
  const [address, setAddress] = useState(defaultAddressState)
  const [userHasBusiness, setUserHasBusiness] = useState(null)
  const [reCaptchaTicked, setReCaptchaTicked] = useState(false)

  const validateForm = (): boolean => {
    const areRequiredFieldsValid = Boolean(
      title && firstName && lastName && email && phone
    )

    const isCompanyNameValid = userHasBusiness ? !!companyName : true
    const isSocialEnterpriseValid = userHasBusiness
      ? !!isSocialEnterprise
      : true
    const isWebsiteValid = website ? isUrl(website) : true
    const isEmailValid = isEmail(email)

    const isAddressValid = Boolean(
      address.street &&
        address.city &&
        address.province &&
        address.zip &&
        address.country
    )

    const recaptchaCheck = !process.env.GATSBY_RECAPTCHA_KEY || reCaptchaTicked

    return (
      areRequiredFieldsValid &&
      isCompanyNameValid &&
      isSocialEnterpriseValid &&
      isWebsiteValid &&
      isEmailValid &&
      isAddressValid &&
      recaptchaCheck
    )
  }

  useEffect(() => {
    setIsFormValid(validateForm())
  })

  const handleFormSubmit = async (
    event: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault()
    const form = document.getElementById('register-form') as HTMLFormElement
    form.submit()
  }

  const handleIsResidentQuestion = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const convertedValue = convertYesNo(event.currentTarget.value)
    setIsResidentOrCitizen(convertedValue ? '1' : '0')
  }

  const handleSocialEnterpriseChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const convertedValue = convertYesNo(event.currentTarget.value)
    setIsSocialEnterprise(convertedValue ? '1' : '0')
  }

  const handleHaveABusinessQuestion = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const convertedValue = convertYesNo(event.currentTarget.value)
    if (!convertedValue) {
      setCompanyName('N/A')
    }
    setUserHasBusiness(convertedValue)
  }

  const handleAddressSelected = (place: Place): void => {
    setAddress(place)
  }

  const handleDateOfBirthChange = (date: string): void => {
    const momentDate = moment(date, 'DD/MM/YYYY')
    if (momentDate.isValid()) {
      setDateOfBirth(momentDate.format('DD/MM/YYYY'))
    }
  }

  const returnUrl = process.env.GATSBY_WEBSITE_URL || ''

  return (
    <FormContainer>
      <meta httpEquiv="Content-type" content="text/html; charset=UTF-8" />
      <form
        id="register-form"
        onSubmit={handleFormSubmit}
        action={CLIENT_SALESFORCE_URL}
        method="POST"
      >
        <input type="hidden" name="oid" defaultValue="00D28000001DwZu" />
        <input type="hidden" name="retURL" value={`${returnUrl}/thank-you/`} />

        <ResidencySection handleIsResidentQuestion={handleIsResidentQuestion} />
        {isResidentOrCitizen === '1' && (
          <OwnerDetailsSection
            handleTitleChange={(e): void => setTitle(e.currentTarget.value)}
            handleFirstNameChange={(e): void => setFirstName(e.target.value)}
            handleLastNameChange={(e): void => setLastName(e.target.value)}
            handleHaveABusinessQuestion={handleHaveABusinessQuestion}
            handleDateOfBirthChange={handleDateOfBirthChange}
            handleEmailChange={(e): void => setEmail(e.target.value)}
            handlePhoneChange={(e): void => setPhone(e.target.value)}
          />
        )}
        {isResidentOrCitizen === '1' && userHasBusiness === false && (
          <FormSection>
            <TextArea
              onChange={(e): void => setAbout(e.target.value)}
              label="Tell us about you and what you would like support with from PBT."
              name="description"
              maxLength={2000}
            />
            <div>{2000 - about.length} characters left.</div>
            <Separator />
            <AddressInput
              onSelect={handleAddressSelected}
              onChangeManualAddress={(street: string): void =>
                setAddress(Object.assign({}, address, { street }))
              }
              onChangeManualSuburb={(province: string): void =>
                setAddress(Object.assign({}, address, { province }))
              }
              onChangeManualCity={(city: string): void =>
                setAddress(Object.assign({}, address, { city }))
              }
              onChangeManualPostcode={(zip: string): void =>
                setAddress(Object.assign({}, address, { zip }))
              }
              label="Address*"
              placeholder="NZ address"
              initialValue={address.formattedAddress}
              onToggle={(): void => setAddress(defaultAddressState)}
            />
          </FormSection>
        )}
        {isResidentOrCitizen === '1' && userHasBusiness && (
          <FormSection>
            <BusinessDetailsSection
              handleSocialEnterpriseChange={handleSocialEnterpriseChange}
              handleCompanyNameChange={(e): void =>
                setCompanyName(e.currentTarget.value)
              }
              handleWebsiteChange={(
                event: ChangeEvent<HTMLInputElement>
              ): void => setWebsite(event.target.value)}
            />
            <AddressInput
              onChangeManualAddress={(street: string): void =>
                setAddress(Object.assign({}, address, { street }))
              }
              onChangeManualSuburb={(province: string): void =>
                setAddress(Object.assign({}, address, { province }))
              }
              onChangeManualCity={(city: string): void =>
                setAddress(Object.assign({}, address, { city }))
              }
              onChangeManualPostcode={(zip: string): void =>
                setAddress(Object.assign({}, address, { zip }))
              }
              initialValue={address.formattedAddress}
              onSelect={handleAddressSelected}
              onToggle={(): void => setAddress(defaultAddressState)}
              label="Address*"
              placeholder="NZ address"
            />
          </FormSection>
        )}

        {/* Hidden fields needed to submit to Salesforce */}
        <div style={{ display: 'none' }}>
          <label>Are you a New Zealand citizen or permanent resident?* </label>
          <input
            id="00N0K00000K6X92"
            name="00N0K00000K6X92"
            type="checkbox"
            checked={isResidentOrCitizen === '1'}
            readOnly
            value={isResidentOrCitizen}
          />
          <label>Are you a Social Enterprise?:</label>
          <input
            id="00N0K00000K6X8w"
            name="00N0K00000K6X8w"
            type="checkbox"
            checked={isSocialEnterprise === '1'}
            readOnly
            value={isSocialEnterprise}
          />
          <br />
          <label>Date of Birth</label>
          <span className="dateInput dateOnlyInput">
            <input
              id="00N2800000Ah1cY"
              name="00N2800000Ah1cY"
              type="text"
              value={dateOfBirth}
              readOnly
            />
          </span>
          <br />
          <label htmlFor="company">Company*</label>
          <input
            id="company"
            maxLength={40}
            name="company"
            size={20}
            type="text"
            value={companyName}
            readOnly
          />
          <br />
          <label htmlFor="street">Street*</label>
          <textarea name="street" value={address.street} readOnly />
          <br />
          <label htmlFor="city">City*</label>
          <input
            id="city"
            maxLength={40}
            name="city"
            size={20}
            type="text"
            value={address.city}
            readOnly
          />
          <br />
          <label htmlFor="zip">Zip*</label>
          <input
            id="zip"
            maxLength={20}
            name="zip"
            size={20}
            type="text"
            value={address.zip}
            readOnly
          />
          <br />
          <label htmlFor="state">State/Province*</label>
          <input
            id="state"
            maxLength={20}
            name="state"
            size={20}
            type="text"
            value={address.province}
            readOnly
          />
          <br />
          <label htmlFor="country">Country*</label>
          <input
            id="country"
            maxLength={40}
            name="country"
            size={20}
            type="text"
            value={address.country}
            readOnly
          />
          <br />
          <input
            type="hidden"
            id="lead_source"
            name="lead_source"
            value="Website Enquiry"
          />
          <input
            type="hidden"
            name="recordType"
            id="recordType"
            value="0120K000001M9Dj"
          />
        </div>

        <RequiredFieldLabel>*Mandatory fields</RequiredFieldLabel>
        {isResidentOrCitizen === '0' && (
          <ErrorMessage data-test-id="nz-resident-error">
            Unfortunately, it’s not possible to become a PBT customer because
            PBT is funded to take care only of citizens and permanent residents.
            <br />
            <br />
            You can still participate in our{' '}
            <a href="/events">Community events</a>.
          </ErrorMessage>
        )}

        {isResidentOrCitizen && userHasBusiness !== null ? (
          <>
            {process.env.GATSBY_RECAPTCHA_KEY && (
              <ReCAPTCHAContainer>
                <ReCAPTCHA
                  sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                  onChange={(value): void => setReCaptchaTicked(value)}
                />
              </ReCAPTCHAContainer>
            )}
            <Button type="submit" disabled={!isFormValid}>
              Send
            </Button>
          </>
        ) : null}
      </form>
    </FormContainer>
  )
}

export default RegistrationForm

import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface Props {
  children: ReactNode
}

const FormSectionComponent = styled.div`
  margin-bottom: 4rem;
`

const FormSection = (props: Props): JSX.Element => {
  return <FormSectionComponent>{props.children}</FormSectionComponent>
}

export default FormSection

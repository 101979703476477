import React, { ChangeEvent, useState } from 'react'
import RadioGroup from '../../atoms/radio-group'
import Row from '../../atoms/row'
import DateInput from '../../atoms/date-input'
import Separator from '../../atoms/separator'
import Input from '../../atoms/input'
import Select from '../../atoms/select'
import FormSection from './form-section'
import isEmail from 'validator/lib/isEmail'

interface Props {
  handleTitleChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleHaveABusinessQuestion: (event: ChangeEvent<HTMLInputElement>) => void
  handleFirstNameChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleLastNameChange: (event: ChangeEvent<HTMLInputElement>) => void
  handlePhoneChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleEmailChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleDateOfBirthChange: (date: string) => void
}

const OwnerDetailsSection = (props: Props): JSX.Element => {
  const [errors, setErrors] = useState({
    title: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  })

  const validateRequiredFieldOnBlur = (value, errorKey): void => {
    if (!value) {
      setErrors(
        Object.assign({}, errors, { [errorKey]: 'This field is required' })
      )
    } else {
      setErrors(Object.assign({}, errors, { [errorKey]: '' }))
    }
  }

  const validateEmail = (value): void => {
    let errorMessage = ''
    if (!value) {
      errorMessage = 'This field is required'
    } else if (!isEmail(value)) {
      errorMessage = 'Invalid email address'
    }
    setErrors(Object.assign({}, errors, { email: errorMessage }))
  }

  return (
    <FormSection>
      <div data-test-id="owner-details">
        <Row>
          <RadioGroup
            name="salutation"
            label="Title*"
            onChange={props.handleTitleChange}
            isHorizontal
            options={['Mr.', 'Miss', 'Ms.', 'Mrs.']}
            errorMessage={errors.title}
            onBlur={(isChecked: boolean): void =>
              validateRequiredFieldOnBlur(isChecked, 'title')
            }
          />
        </Row>
        <Row>
          <Input
            onChange={props.handleFirstNameChange}
            label="First Name*"
            placeholder="Business owner's first name"
            name="first_name"
            errorMessage={errors.firstName}
            onBlur={(e: ChangeEvent<HTMLInputElement>): void =>
              validateRequiredFieldOnBlur(e.target.value, 'firstName')
            }
          />
          <Separator />
          <Input
            onChange={props.handleLastNameChange}
            label="Last Name*"
            placeholder="Business owner's last name"
            name="last_name"
            errorMessage={errors.lastName}
            onBlur={(e: ChangeEvent<HTMLInputElement>): void =>
              validateRequiredFieldOnBlur(e.target.value, 'lastName')
            }
          />
        </Row>
        <Row>
          <DateInput
            onChange={props.handleDateOfBirthChange}
            label="Date of Birth"
          />
          <Separator />
          <Select
            name="00N2800000Ah1cJ"
            label="Ethnicity"
            placeholder="Select your primary ethnicity"
            options={[
              'Cook Islands',
              'Fijian',
              'Kiribati',
              'Niuean',
              'Samoan',
              'Tahitian',
              'Tokelauan',
              'Tuvalaun',
              'Tongan',
              'Other',
            ]}
          />
        </Row>
        <Row>
          <Input
            onChange={props.handlePhoneChange}
            label="Phone Number*"
            placeholder="Insert your number e.g. 0212345678"
            subText="(Numbers only. No spaces or any other characters)"
            name="phone"
            type="number"
            errorMessage={errors.phone}
            onBlur={(e: ChangeEvent<HTMLInputElement>): void =>
              validateRequiredFieldOnBlur(e.target.value, 'phone')
            }
          />
          <Separator />
          <Input
            onChange={props.handleEmailChange}
            label="Email*"
            placeholder="Email address"
            name="email"
            errorMessage={errors.email}
            onBlur={(e: ChangeEvent<HTMLInputElement>): void =>
              validateEmail(e.target.value)
            }
          />
        </Row>
        <h3>Business</h3>
        <Row>
          <RadioGroup
            name="have-a-business"
            label="Do you currently have a business in New Zealand?*"
            onChange={props.handleHaveABusinessQuestion}
            options={['yes', 'no']}
          />
        </Row>
      </div>
    </FormSection>
  )
}

export default OwnerDetailsSection

import React, { useState, ChangeEvent } from 'react'
import styled from 'styled-components'
import 'react-google-places-autocomplete/dist/assets/index.css'
import GoogleAutocomplete from './google-autocomplete'
import ManualEntry from './manual-address'
import { compose, withProps } from 'recompose'
import { withScriptjs } from 'react-google-maps'

interface InputProps {
  label: string
  placeholder: string
  showError?: boolean
  initialValue?: string
  onChangeManualAddress?: (street: string) => void
  onChangeManualSuburb?: (province: string) => void
  onChangeManualCity?: (city: string) => void
  onChangeManualPostcode?: (postcode: string) => void
  onSelect?: (place: {
    street: string
    province: string
    city: string
    zip: string
    country: string
    formattedAddress: string
  }) => void
  onBlur?: (value: string, key: string) => void
  onToggle?: () => void
}

const ClickableText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

const AddressInput = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs
)(
  (props: InputProps): JSX.Element => {
    const [isManualEntry, setIsManualEntry] = useState(false)
    const [errors, setErrors] = useState({
      googleAddress: '',
      manualStreet: '',
      manualProvince: '',
      manualCity: '',
      manualPostcode: '',
    })

    const validateRequiredFieldOnBlur = (value, errorKey): void => {
      if (!value) {
        setErrors(
          Object.assign({}, errors, { [errorKey]: 'This field is required' })
        )
      } else {
        setErrors(Object.assign({}, errors, { [errorKey]: '' }))
      }
    }

    const toggleInput = (): void => {
      setIsManualEntry(!isManualEntry)
      if (props.onToggle) {
        props.onToggle()
      }
    }
    return (
      <React.Fragment>
        <div id="map"></div>
        {isManualEntry ? (
          <ManualEntry
            handleAddressChange={(e: ChangeEvent<HTMLInputElement>): void =>
              props.onChangeManualAddress(e.target.value)
            }
            handleSuburbChange={(e: ChangeEvent<HTMLInputElement>): void =>
              props.onChangeManualSuburb(e.target.value)
            }
            handleCityChange={(e: ChangeEvent<HTMLInputElement>): void =>
              props.onChangeManualCity(e.target.value)
            }
            handlePostcodeChange={(e: ChangeEvent<HTMLInputElement>): void =>
              props.onChangeManualPostcode(e.target.value)
            }
            onBlur={validateRequiredFieldOnBlur}
            errors={errors}
          />
        ) : (
          <GoogleAutocomplete
            {...props}
            onBlur={validateRequiredFieldOnBlur}
            errorMessage={errors.googleAddress}
          />
        )}

        {isManualEntry ? (
          <p>
            <ClickableText onClick={toggleInput}>
              Search for your address instead
            </ClickableText>
          </p>
        ) : (
          <p>
            Can&apos;t find your address?{' '}
            <ClickableText onClick={toggleInput}>
              Enter it manually
            </ClickableText>
          </p>
        )}
      </React.Fragment>
    )
  }
)

export default AddressInput

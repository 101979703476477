import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import theme from '../../atoms/theme'

const tickSrc = require('../../../assets/orange-circle-tick.svg') as string

const ThankYouContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7.5rem;
  border-right: 0.1rem solid ${theme.colours.gray3};
  border-left: 0.1rem solid ${theme.colours.gray3};
  border-bottom: 0.1rem solid ${theme.colours.gray3};
  padding-bottom: 10rem;
  margin-bottom: 2.8rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    border: none;
  }
`

const Title = styled.h3`
  text-transform: uppercase;
  color: ${theme.colours.secondary};
  margin-top: 3rem;
`

const Tick = styled.img`
  width: 5rem;
`

const BodyText = styled.div`
  line-height: 2;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    text-align: center;
    margin-bottom: 2rem;
  }
`

const ThankYouSection = (): JSX.Element => {
  const containerRef = useRef(null)

  useEffect(() => {
    containerRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [containerRef])

  return (
    <ThankYouContainer ref={containerRef}>
      <a href="#section" />
      <Tick src={tickSrc} alt="Tick" />
      <Title>Registration form sent</Title>
      <BodyText>We have received your application.</BodyText>
      <BodyText>
        Someone from our staff will get in touch with you within 3 business
        days.
      </BodyText>
    </ThankYouContainer>
  )
}

export default ThankYouSection
